import "./src/styles/global.css";
import React from "react";
import type { GatsbyBrowser } from "gatsby";

const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element }) => {
  return (
    <>
      {element}
    </>
  );
};

export { wrapPageElement };
